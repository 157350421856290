import { Authcontext } from "@/utils/userAuth";
import React from "react";

export const useCurrentUser = () => {
  const ctx = React.useContext(Authcontext);
  if (ctx == null) {
    throw new Error("useCurrentUser must be used within a UserProvider");
  }
  return ctx.currentUser;
};

export const useSetCurrentUser = () => {
  const ctx = React.useContext(Authcontext);
  if (ctx == null) {
    throw new Error("useSetCurrentUser must be used within a UserProvider");
  }
  return ctx.setCurrentUser;
};

export const useCurrentUserState = () => {
  const ctx = React.useContext(Authcontext);
  if (ctx == null) {
    throw new Error("useCurrentUserState must be used within a UserProvider");
  }
  return ctx.userState;
};

export const useSetCurrentUserState = () => {
  const ctx = React.useContext(Authcontext);
  if (ctx == null) {
    throw new Error(
      "useSetCurrentUserState must be used within a UserProvider"
    );
  }
  return ctx.setUserState;
};
