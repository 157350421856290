import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  Path,
} from "react-hook-form";

type itemObj = {
  label: string;
  value: string | number;
};

type Props<T extends Record<string, any>> = React.ComponentProps<
  typeof Select
> & {
  control: Control<T>;
  name: Path<T>;
  label: string;
  items: itemObj[];
  fontSize: number;
  width: number | string;
  setValue: UseFormSetValue<T>;
  getValues: UseFormGetValues<T>;
};

const SelectForForm = <T extends Record<string, any>>({
  control,
  name,
  label,
  items,
  fontSize,
  width,
  setValue,
  getValues,
  ...selectFieldProps
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl
          fullWidth
          error={fieldState.invalid}
          sx={{ background: "white" }}
          size="small"
        >
          <InputLabel
            required
            shrink
            sx={{
              fontSize: fontSize,
              "& .MuiInputLabel-asterisk": {
                ml: 0.5,
                color: "rgb(211, 47, 47)",
              },
              "& .MuiFormHelperText-root": {
                background: "#F7E9D7",
                m: 0,
              },
            }}
          >
            {label}
          </InputLabel>

          <Select
            {...field}
            label={name}
            sx={{
              fontSize: fontSize,
              width: width,
              "& .MuiSelect-select": { px: 0.7 },
              "& .MuiPaper-root": {
                fontSize: fontSize,
              },
              "& .MuiMenuItem-root": {
                fontSize: fontSize,
              },
            }}
            {...selectFieldProps}
          >
            {items.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                sx={{ fontSize: fontSize }}
                dense
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
          {fieldState.error && (
            <FormHelperText>{fieldState.error.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default SelectForForm;
