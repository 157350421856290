export const UserRoleValues = ["guest", "free", "pro", "admin"] as const;

export const BlueprintPatternValues = [
  "generation",
  "revising_existing_report",
  "training_text",
  "upload_file",
] as const;

export const BlueprintStatusValues = [
  "revisable",
  "unrevisable",
  "deleted",
] as const;

export const BillingPlanValues = [
  "monthly_plan",
  "annual_plan",
  "eternal_plan",
] as const;

export const BlueprintFormatValues = ["standard", "polite"] as const;

export const BlueprintLanguageValues = ["japanese", "english"] as const;

export const BlueprintToneValues = [
  "professional",
  "casual",
  "straightforward",
  "confident",
  "friendly",
] as const;

export const BlueprintQualityValues = [
  "beginner",
  "intermediate",
  "advanced",
] as const;

export const RevisingActionValues = [
  "instruct",
  "make_longer",
  "make_shorter",
  "summarize",
  "change_tone",
  "fix_spelling_grammar",
  "translate",
  "reference",
  "replace",
  "continue",
  "feedback",
] as const;

export const ThreadStatusValues = ["active", "deleted", "resolved"] as const;

export const CommentStatusValues = ["active", "deleted"] as const;

export const StreamingStatusValues = [
  "not_yet",
  "progress",
  "finished",
] as const;

export const RevisingOutputFeedbackTypeValues = ["good", "bad"] as const;

export const JobCategoryValues = [
  "strategic_comprehensive_consultant",
  "system_it_consultant",
  "trader_dealer",
  "corporate_planning_business_planning",
  "research_marketing",
  "product_planning_planning",
  "advertising_public_relations",
  "general_affairs_hr_labor_relations",
  "accounting_finance_accounting",
  "legal",
  "director_producer",
  "web_engineer",
  "data_scientist",
  "system_engineer",
  "smartphone_app_engineer",
  "machine_learning_engineer",
  "basic_research_applied_research",
  "mechanical_electrical_electronic_design",
  "general_sales",
  "technical_sales_system_sales",
  "game_creator",
  "ui_ux_designer",
  "editing_production",
  "comprehensive_position",
  "public_officer",
  "pharmacist",
] as const;

export const JobCategoryJPValues = [
  "戦略・総合コンサルタント",
  "システム・ITコンサルタント",
  "トレーダー・ディーラー",
  "経営企画・事業企画",
  "調査・マーケティング",
  "商品企画・プランニング",
  "宣伝・広報",
  "総務・人事・労務",
  "経理・財務・会計",
  "法務",
  "ディレクター・プロデューサー",
  "webエンジニア",
  "データサイエンティスト",
  "SE",
  "スマホアプリエンジニア",
  "機械学習エンジニア",
  "基礎研究・応用研究",
  "機械・電気・電子機器設計",
  "一般営業",
  "技術営業・システム営業",
  "ゲームクリエイター",
  "UI・UXデザイナー",
  "編集・制作",
  "総合職",
  "公務員",
  "薬剤師",
] as const;

export const BusinessCategoryValues = [
  "it_communications_internet",
  "manufacturer",
  "trading_company",
  "service_leisure",
  "distribution_retail_food",
  "mass_media_advertising_design",
  "finance_insurance",
  "consulting",
  "real_estate_construction_facilities",
  "environment_energy",
  "public_institution",
] as const;

export const BusinessCategoryJPValues = [
  "IT・通信・インターネット",
  "メーカー",
  "商社",
  "サービス・レジャー",
  "流通・小売・フード",
  "マスコミ・広告・デザイン",
  "金融・保険",
  "コンサルティング",
  "不動産・建築・設備",
  "環境・エネルギー",
  "公的機関",
] as const;

export const RevisingOutputFeedbackReasonValues = [
  "based_fact",
  "satisfactory",
  "followed_instruction",
  "good_other",
  "not_based_fact",
  "not_followed_instruction",
  "poor_quality",
  "bad_other",
] as const;

export const UserProfileSexValues = ["male", "female"] as const;

export const UserProfileSchoolTypeValues = [
  "college",
  "college_of_technology",
  "graduate_school",
  "high_school",
  "junior_college",
  "vacational_school",
] as const;

export const UserProfileAcademicCategoryValues = [
  "electrical_engineering",
  "mechanical_engineering",
  "mathematics",
  "architectural_engineering",
  "biochemistry",
  "earth_sciences",
  "economics",
  "law",
  "education",
  "human_sciences",
  "physical_education",
  "human_environmental_sciences",
  "international_studies",
  "literature",
  "NA",
] as const;

export const UserProfilePrefectureValues = [
  "Hokkaido",
  "Aomori",
  "Iwate",
  "Miyagi",
  "Akita",
  "Yamagata",
  "Fukushima",
  "Ibaraki",
  "Tochigi",
  "Gunma",
  "Saitama",
  "Chiba",
  "Tokyo",
  "Kanagawa",
  "Niigata",
  "Toyama",
  "Ishikawa",
  "Fukui",
  "Yamanashi",
  "Nagano",
  "Gifu",
  "Shizuoka",
  "Aichi",
  "Mie",
  "Shiga",
  "Kyoto",
  "Osaka",
  "Hyogo",
  "Nara",
  "Wakayama",
  "Tottori",
  "Shimane",
  "Okayama",
  "Hiroshima",
  "Yamaguchi",
  "Tokushima",
  "Kagawa",
  "Ehime",
  "Kochi",
  "Fukuoka",
  "Saga",
  "Nagasaki",
  "Kumamoto",
  "Oita",
  "Miyazaki",
  "Kagoshima",
  "Okinawa",
] as const;

export type UserRoleType = (typeof UserRoleValues)[number];
export type BlueprintPatternType = (typeof BlueprintPatternValues)[number];
export type BlueprintStatusType = (typeof BlueprintStatusValues)[number];
export type BillingPlanType = (typeof BillingPlanValues)[number];
export type BlueprintFormatType = (typeof BlueprintFormatValues)[number];
export type BlueprintLanguageType = (typeof BlueprintLanguageValues)[number];
export type BlueprintToneType = (typeof BlueprintToneValues)[number];
export type BlueprintQualityType = (typeof BlueprintQualityValues)[number];
export type RevisingActionType = (typeof RevisingActionValues)[number];
export type ThreadStatusType = (typeof ThreadStatusValues)[number];
export type CommentStatusType = (typeof CommentStatusValues)[number];
export type StreamingStatusType = (typeof StreamingStatusValues)[number];
export type RevisingOutputFeedbackTypeType =
  (typeof RevisingOutputFeedbackTypeValues)[number];
export type RevisingOutputFeedbackReasonType =
  (typeof RevisingOutputFeedbackReasonValues)[number];
export type UserProfileSexType = (typeof UserProfileSexValues)[number];
export type UserProfileSchoolTypeType =
  (typeof UserProfileSchoolTypeValues)[number];
export type UserProfileAcademicCategoryType =
  (typeof UserProfileAcademicCategoryValues)[number];
export type UserProfilePrefectureType =
  (typeof UserProfilePrefectureValues)[number];
export type JobCategoryType = (typeof JobCategoryValues)[number];
export type BusinessCategoryType = (typeof BusinessCategoryValues)[number];
export type JobCategoryJPType = (typeof JobCategoryJPValues)[number];
export type BusinessCategoryJPType = (typeof BusinessCategoryJPValues)[number];

export const BillingPlanMap: { [key in BillingPlanType]: string } = {
  monthly_plan: "プレミアム月額プラン",
  annual_plan: "プレミアム年額プラン",
  eternal_plan: "プレミアム永年プラン",
};

export const UserProfileSexMap: { [key in UserProfileSexType]: string } = {
  male: "男性",
  female: "女性",
};

export const UserProfileAcademicCategoryMap: {
  [key in UserProfileAcademicCategoryType]: string;
} = {
  electrical_engineering: "電気・電子系",
  mechanical_engineering: "機械・工学系",
  mathematics: "理学・数学系",
  architectural_engineering: "建築・土木系",
  biochemistry: "生物・農学系",
  earth_sciences: "地球・環境系",
  economics: "経済・商学系",
  law: "法学・社会・福祉系",
  education: "教育・教員養成系",
  human_sciences: "人間・総合科学系",
  physical_education: "体育・医療系",
  human_environmental_sciences: "生活・家政系",
  international_studies: "国際・語学系",
  literature: "文化・文学系",
  NA: "該当なし",
};

export const UserProfileSchoolTypeMap: {
  [key in UserProfileSchoolTypeType]: string;
} = {
  college: "大学",
  college_of_technology: "高専",
  graduate_school: "大学院",
  high_school: "高等学校",
  junior_college: "短大",
  vacational_school: "専門学校",
};

export const JobCategoryMap: { [key in JobCategoryType]: JobCategoryJPType } = {
  strategic_comprehensive_consultant: "戦略・総合コンサルタント",
  system_it_consultant: "システム・ITコンサルタント",
  trader_dealer: "トレーダー・ディーラー",
  corporate_planning_business_planning: "経営企画・事業企画",
  research_marketing: "調査・マーケティング",
  product_planning_planning: "商品企画・プランニング",
  advertising_public_relations: "宣伝・広報",
  general_affairs_hr_labor_relations: "総務・人事・労務",
  accounting_finance_accounting: "経理・財務・会計",
  legal: "法務",
  director_producer: "ディレクター・プロデューサー",
  web_engineer: "webエンジニア",
  data_scientist: "データサイエンティスト",
  system_engineer: "SE",
  smartphone_app_engineer: "スマホアプリエンジニア",
  machine_learning_engineer: "機械学習エンジニア",
  basic_research_applied_research: "基礎研究・応用研究",
  mechanical_electrical_electronic_design: "機械・電気・電子機器設計",
  general_sales: "一般営業",
  technical_sales_system_sales: "技術営業・システム営業",
  game_creator: "ゲームクリエイター",
  ui_ux_designer: "UI・UXデザイナー",
  editing_production: "編集・制作",
  comprehensive_position: "総合職",
  public_officer: "公務員",
  pharmacist: "薬剤師",
};

export const JobCategoryReverseMap: { [key in string]: JobCategoryType } = {
  "戦略・総合コンサルタント": "strategic_comprehensive_consultant",
  "システム・ITコンサルタント": "system_it_consultant",
  "トレーダー・ディーラー": "trader_dealer",
  "経営企画・事業企画": "corporate_planning_business_planning",
  "調査・マーケティング": "research_marketing",
  "商品企画・プランニング": "product_planning_planning",
  "宣伝・広報": "advertising_public_relations",
  "総務・人事・労務": "general_affairs_hr_labor_relations",
  "経理・財務・会計": "accounting_finance_accounting",
  法務: "legal",
  "ディレクター・プロデューサー": "director_producer",
  webエンジニア: "web_engineer",
  データサイエンティスト: "data_scientist",
  SE: "system_engineer",
  スマホアプリエンジニア: "smartphone_app_engineer",
  機械学習エンジニア: "machine_learning_engineer",
  "基礎研究・応用研究": "basic_research_applied_research",
  "機械・電気・電子機器設計": "mechanical_electrical_electronic_design",
  一般営業: "general_sales",
  "技術営業・システム営業": "technical_sales_system_sales",
  ゲームクリエイター: "game_creator",
  "UI・UXデザイナー": "ui_ux_designer",
  "編集・制作": "editing_production",
  総合職: "comprehensive_position",
  公務員: "public_officer",
  薬剤師: "pharmacist",
};

export const BusinessCategoryMap: {
  [key in BusinessCategoryType]: BusinessCategoryJPType;
} = {
  it_communications_internet: "IT・通信・インターネット",
  manufacturer: "メーカー",
  trading_company: "商社",
  service_leisure: "サービス・レジャー",
  distribution_retail_food: "流通・小売・フード",
  mass_media_advertising_design: "マスコミ・広告・デザイン",
  finance_insurance: "金融・保険",
  consulting: "コンサルティング",
  real_estate_construction_facilities: "不動産・建築・設備",
  environment_energy: "環境・エネルギー",
  public_institution: "公的機関",
};

export const BusinessCategoryReverseMap: {
  [key in string]: BusinessCategoryType;
} = {
  "IT・通信・インターネット": "it_communications_internet",
  メーカー: "consulting",
  商社: "trading_company",
  "サービス・レジャー": "service_leisure",
  "流通・小売・フード": "distribution_retail_food",
  "マスコミ・広告・デザイン": "mass_media_advertising_design",
  "金融・保険": "finance_insurance",
  コンサルティング: "consulting",
  "不動産・建築・設備": "real_estate_construction_facilities",
  "環境・エネルギー": "environment_energy",
  公的機関: "public_institution",
};

export const UserProfilePrefectureMap: {
  [key in UserProfilePrefectureType]: string;
} = {
  Hokkaido: "北海道",
  Aomori: "青森",
  Iwate: "岩手",
  Miyagi: "宮城",
  Akita: "秋田",
  Yamagata: "山形",
  Fukushima: "福島",
  Ibaraki: "茨城",
  Tochigi: "栃木",
  Gunma: "群馬",
  Saitama: "埼玉",
  Chiba: "千葉",
  Tokyo: "東京",
  Kanagawa: "神奈川",
  Niigata: "新潟",
  Toyama: "富山",
  Ishikawa: "石川",
  Fukui: "福井",
  Yamanashi: "山梨",
  Nagano: "長野",
  Gifu: "岐阜",
  Shizuoka: "静岡",
  Aichi: "愛知",
  Mie: "三重",
  Shiga: "滋賀",
  Kyoto: "京都",
  Osaka: "大阪",
  Hyogo: "兵庫",
  Nara: "奈良",
  Wakayama: "和歌山",
  Tottori: "鳥取",
  Shimane: "島根",
  Okayama: "岡山",
  Hiroshima: "広島",
  Yamaguchi: "山口",
  Tokushima: "徳島",
  Kagawa: "香川",
  Ehime: "愛媛",
  Kochi: "高知",
  Fukuoka: "福岡",
  Saga: "佐賀",
  Nagasaki: "長崎",
  Kumamoto: "熊本",
  Oita: "大分",
  Miyazaki: "宮崎",
  Kagoshima: "鹿児島",
  Okinawa: "沖縄",
};
