import { UserAdditionalInfo } from "@/utils/interfaces";
import { apiClient } from "./apiClient";

export type UserAdditionalInfoCreateRequest = {
  uuid: string;
  ip_address: string;
  network: string;
  ip_version: string;
};

export type UserAdditionalInfoUpdateRequest = {
  uuid: string;
  user_id: string;
  ip_address: string;
  network: string;
  ip_version: string;
};

export const create = async (params: UserAdditionalInfoCreateRequest) => {
  const response = await apiClient.post(`/api/user_additional_info`, params);
  const data = response.data as UserAdditionalInfo;
  return data;
};

export const connect = async (params: UserAdditionalInfoUpdateRequest) => {
  const response = await apiClient.post(
    `api/user_additional_info/connect`,
    params
  );
  const data = response.data as UserAdditionalInfo;
  return data;
};
