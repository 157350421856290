import { getMe, initialize } from "../api/user";
import React from "react";
import { User } from "./interfaces";
import { useShowError } from "@/hooks/notification";
import Cookies from "js-cookie";
import {
  UserAdditionalInfoCreateRequest,
  UserAdditionalInfoUpdateRequest,
  connect,
  create as createInfo,
} from "@/api/userAdditionalInfo";
import { AxiosError } from "axios";

type UserState = "idle" | "anonymous" | "progress" | "logined";

type AuthContextType = {
  currentUser: User | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
  userState: UserState;
  setUserState: React.Dispatch<React.SetStateAction<UserState>>;
};

export const Authcontext = React.createContext<AuthContextType | null>(null);

type IPAPIResponse = {
  ip: string;
  network: string;
  version: string;
};

export const UserAuthProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [currentUser, setCurrentUser] = React.useState<User | null>(null);
  const [userState, setUserState] = React.useState<UserState>("idle");
  const showError = useShowError();

  const initializeGuestUser = React.useCallback(async () => {
    if (currentUser) return;

    try {
      const response = await initialize();
      setCurrentUser(response);
    } catch (err) {
      showError(err);
    }
  }, [showError, currentUser]);

  const fetchMe = React.useCallback(async () => {
    if (currentUser) return;
    setUserState("progress");

    try {
      const response = await getMe();
      setCurrentUser(response);
      if (response.role == "guest") {
        setUserState("anonymous");
      } else if (
        response.role == "admin" ||
        response.role == "pro" ||
        response.role == "free"
      ) {
        setUserState("logined");
      }
    } catch (err) {
      if (err instanceof AxiosError && err?.response?.status == 401) {
        initializeGuestUser();
      } else {
        showError(err);
      }
    }
  }, [showError, currentUser, initializeGuestUser]);

  const setUserAdditionalInfo = React.useCallback(async () => {
    if (userState == "idle" || userState == "progress") return;

    try {
      if (!Cookies.get("uuid")) return;

      // ユーザーのipアドレスなどを取得
      const ip = await fetch("https://ipapi.co/json/");
      const data = await ip.json();
      const ipConfig = data as IPAPIResponse;
      if (currentUser) {
        const req: UserAdditionalInfoUpdateRequest = {
          uuid: Cookies.get("uuid") as string,
          user_id: String(currentUser.id),
          ip_address: ipConfig.ip,
          network: ipConfig.network,
          ip_version: ipConfig.version,
        };
        await connect(req);
      } else {
        const req: UserAdditionalInfoCreateRequest = {
          uuid: Cookies.get("uuid") as string,
          ip_address: ipConfig.ip,
          network: ipConfig.network,
          ip_version: ipConfig.version,
        };
        await createInfo(req);
      }
    } catch (err) {
      showError(err);
    }
  }, [showError, currentUser, userState]);

  React.useEffect(() => {
    fetchMe();
  }, [fetchMe]);

  return (
    <Authcontext.Provider
      value={{ currentUser, setCurrentUser, userState, setUserState }}
    >
      {props.children}
    </Authcontext.Provider>
  );
};
