import { useCurrentKeyword, useSetCurrentKeyword } from "@/utils/blueprint";
import {
  Dialog,
  TextField,
  Typography,
  Paper,
  PaperProps,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { BlueprintGetAllRequest, getAll } from "@/api/blueprint";
import { Blueprint } from "@/utils/interfaces";
import { useShowError } from "@/hooks/notification";
import SearchIcon from "@mui/icons-material/Search";
import BlueprintListInSearchBox from "@/molecules/BlueprintListInSearchBox";

type Props = {
  openSearchingDialog: boolean;
  handleCloseDialog: () => void;
};

const BlueprintSearchDialog: React.FC<Props> = ({
  openSearchingDialog,
  handleCloseDialog,
}) => {
  const showError = useShowError();
  const [blueprints, setBlueprints] = React.useState<Blueprint[]>([]);
  const currentKeyword = useCurrentKeyword();
  const setCurrentKeyword = useSetCurrentKeyword();
  const isMobile = useMediaQuery("(max-width: 600px)");

  const fetchBlueprints = React.useCallback(
    async (keyword: string) => {
      try {
        const params: BlueprintGetAllRequest = {
          keyword: keyword,
          is_star: null,
          sort_by: "id",
          order: "desc",
        };
        const response = await getAll(params);
        setBlueprints(response);
      } catch (err) {
        showError(err);
      }
    },
    [showError]
  );

  const handleOnChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setCurrentKeyword(e.currentTarget.value);
      fetchBlueprints(e.currentTarget.value);
    },
    [setCurrentKeyword, fetchBlueprints]
  );

  return (
    <>
      <Dialog
        onClose={handleCloseDialog}
        open={openSearchingDialog}
        fullWidth
        maxWidth="md"
        sx={{ p: 2 }}
        PaperComponent={(props: PaperProps) => (
          <Paper {...props} sx={{ p: 2, borderRadius: 3 }} />
        )}
      >
        <TextField
          autoFocus
          variant="outlined"
          onChange={handleOnChange}
          value={currentKeyword}
          placeholder="レポートを検索する"
          sx={{
            "& .MuiInputBase-input": { py: 1 },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <Typography sx={{ mt: 2, color: "rgba(37, 37, 37, 0.70)" }}>
          検索結果
        </Typography>

        <BlueprintListInSearchBox blueprints={blueprints} isMobile={isMobile} />
      </Dialog>
    </>
  );
};

export default BlueprintSearchDialog;
