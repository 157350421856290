import React from "react";

type UserSideMenuDrawerContextType = {
  showSideMenuDrawer: boolean;
  setShowSideMenuDrawer: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UserSideMenuDrawerContext =
  React.createContext<UserSideMenuDrawerContextType | null>(null);

export const useShowSideMenuDrawer = () => {
  const ctx = React.useContext(UserSideMenuDrawerContext);
  if (ctx == null) {
    throw new Error(
      "useShowSideMenuDrawer must be used within a UserSideMenuDrawerProvider"
    );
  }
  return ctx.showSideMenuDrawer;
};

export const useSetShowSideMenuDrawer = () => {
  const ctx = React.useContext(UserSideMenuDrawerContext);
  if (ctx == null) {
    throw new Error(
      "useSetShowSideMenuDrawer must be used within a UserSideMenuDrawerProvider"
    );
  }
  return ctx.setShowSideMenuDrawer;
};

export const UserSideMenuDrawerProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [showSideMenuDrawer, setShowSideMenuDrawer] = React.useState(false);

  return (
    <UserSideMenuDrawerContext.Provider
      value={{ showSideMenuDrawer, setShowSideMenuDrawer }}
    >
      {children}
    </UserSideMenuDrawerContext.Provider>
  );
};
