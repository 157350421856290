import { Alert, List, ListItem, Snackbar } from "@mui/material";
import React from "react";

type NotificationType = "success" | "info" | "warning" | "error";

type Notification = {
  type: NotificationType;
  message: string | string[];
  open: boolean;
};

type NotificationContextType = {
  notify: Notification;
  setNotify: (notify: Notification) => void;
  handleCloseNotify: () => void;
};

export const NotificationStateContext =
  React.createContext<NotificationContextType | null>(null);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [notify, setNotify] = React.useState<Notification>({
    type: "success",
    message: "",
    open: false,
  });

  const handleCloseNotify = React.useCallback(() => {
    setNotify({
      ...notify,
      open: false,
    });
  }, [setNotify, notify]);

  return (
    <NotificationStateContext.Provider
      value={{ notify, setNotify, handleCloseNotify }}
    >
      <Snackbar
        open={notify.open}
        autoHideDuration={5000}
        onClose={handleCloseNotify}
      >
        <Alert
          onClose={handleCloseNotify}
          severity={notify.type}
          sx={{ width: "100%" }}
        >
          {typeof notify.message == "string" ? (
            notify.message
          ) : (
            <List>
              {notify.message?.map((m, i) => (
                <ListItem key={i}>{m}</ListItem>
              ))}
            </List>
          )}
        </Alert>
      </Snackbar>
      {children}
    </NotificationStateContext.Provider>
  );
};
