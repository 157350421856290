import { User } from "../utils/interfaces";
import { apiClient } from "./apiClient";

export type UserCreateRequest = {
  uuid: string;
  name: string;
  email: string;
  icon: string;
  role: "free" | "pro" | "admin" | "guest";
};

export const create = async (params: UserCreateRequest) => {
  const response = await apiClient.post(`/api/user`, params);
  const data = response.data as User;
  return data;
};

export const initialize = async () => {
  const response = await apiClient.post(`api/user/initialize`);
  const data = response.data as User;
  return data;
};

export const getMe = async () => {
  const response = await apiClient.get(`api/user/me`);
  const data = response.data as User;
  return data;
};

export const get = async (id: string) => {
  const response = await apiClient.get(`/api/user/${id}`);
  const data = response.data as User;
  return data;
};

export const getAll = async () => {
  const response = await apiClient.get(`/api/user`);
  const data = response.data as User[];
  return data;
};
