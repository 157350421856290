import { UserProfile } from "@/utils/interfaces";
import { apiClient } from "./apiClient";

export type UserProfileCreateRequest = {
  sex: string | null;
  school_type: string | null;
  school_name: string | null;
  academic_category: string | null;
  major: string | null;
  graduate_year: number | null;
  phone_number: string | null;
  postal_code: string | null;
  prefecture: string | null;
  city: string | null;
  address1: string | null;
  address2: string | null;
  self_pr: string | null;
  interesting_jobs: string[] | null;
  interesting_businesses: string[] | null;
};

export type UserProfileUpdateRequest = {
  sex: string | null;
  school_type: string | null;
  school_name: string | null;
  academic_category: string | null;
  major: string | null;
  graduate_year: number | null;
  phone_number: string | null;
  postal_code: string | null;
  prefecture: string | null;
  city: string | null;
  address1: string | null;
  address2: string | null;
  self_pr: string | null;
  interesting_jobs: string[] | null;
  interesting_businesses: string[] | null;
};

export const get = async () => {
  const response = await apiClient.get(`/api/user_profile`);
  const data = response.data as UserProfile;
  return data;
};

export const create = async (params: UserProfileCreateRequest) => {
  const response = await apiClient.post(`/api/user_profile`, params);
  const data = response.data as UserProfile;
  return data;
};

export const update = async (params: UserProfileUpdateRequest) => {
  const response = await apiClient.patch(`/api/user_profile`, params);
  const data = response.data as UserProfile;
  return data;
};
