import React from "react";
import { Button } from "@mui/material";
import { SxProps } from "@mui/system";

const whiteTone: SxProps = {
  backgroundColor: "#ffffff",
  color: "#000000",
  ":hover": {
    backgroundColor: "#ff8c00",
    borderColor: "#ff8c00",
    color: "#ffffff",
  },
};

const orangeTone: SxProps = {
  backgroundColor: "#ff8c00",
  color: "#ffffff",
  ":hover": {
    backgroundColor: "#ff4d00",
    color: "#ffffff",
    borderColor: "#ff4d00",
  },
};

const lightOrangeTone: SxProps = {
  backgroundColor: "#FF8C00B2",
  color: "#ffffff",
  ":hover": {
    backgroundColor: "#ff8c00",
    color: "#ffffff",
    borderColor: "#ff8c00",
  },
  "&.Mui-disabled": {
    color: "darkgray",
    bgcolor: "lightgrey",
  },
};

type toneType = "white" | "orange" | "light-orange";

type Props = React.ComponentProps<typeof Button> & {
  message: React.ReactNode;
  toneType: toneType;
  sx?: any;
  onClick?: () => void;
};

const getButtonTone = (type: toneType): SxProps => {
  switch (type) {
    case "white":
      return whiteTone;
    case "orange":
      return orangeTone;
    case "light-orange":
      return lightOrangeTone;
    default:
      return orangeTone;
  }
};

const GeneralButton: React.FC<Props> = ({
  message,
  toneType,
  sx,
  onClick,
  ...otherProps
}) => {
  const handleFunc = (fn?: () => void) => {
    if (!fn) return;

    fn();
  };

  const tone: SxProps = getButtonTone(toneType);

  const defaultSx: SxProps = {
    ...tone,
    ...sx,
  };

  return (
    <Button sx={defaultSx} onClick={() => handleFunc(onClick)} {...otherProps}>
      {message}
    </Button>
  );
};

export default GeneralButton;
