import {
  IconButton,
  Popper,
  Typography,
  Paper,
  Button,
  ClickAwayListener,
} from "@mui/material";
import React from "react";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { showSupportingWidgets } from "@/utils/intercom";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const HelpPopper: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          position: "fixed",
          bottom: 10,
          right: 15,
          background: "white",
          width: 35,
          height: 35,
          boxShadow: 4,
          zIndex: 1000,
          ":hover": { background: "#fbf8f8" },
        }}
      >
        <Typography sx={{ color: "black", fontSize: 20 }}>?</Typography>
      </IconButton>

      {Boolean(anchorEl) && (
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement="top-end"
          >
            <Paper sx={{ p: 1, textAlign: "left", width: 200 }}>
              <Button
                sx={{
                  color: "rgba(37, 37, 37, 0.70)",
                  px: 1,
                  width: "100%",
                  fontSize: 13,
                  textAlign: "left",
                  justifyContent: "flex-start",
                }}
                component="a"
                href="https://help.report-supporter.com/ja/articles/9119736-%E3%83%AC%E3%83%9D%E3%83%BC%E3%83%88%E3%81%8A%E5%8A%A9%E3%81%91%E3%81%8F%E3%82%93%E3%81%AE%E5%A7%8B%E3%82%81%E6%96%B9"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LightbulbIcon sx={{ fontSize: 20, mr: 0.5 }} />
                <Typography sx={{ fontSize: 13, color: "black" }}>
                  スタートアップガイド
                </Typography>
              </Button>
              <Button
                sx={{
                  color: "rgba(37, 37, 37, 0.70)",
                  px: 1,
                  width: "100%",
                  fontSize: 13,
                  textAlign: "left",
                  justifyContent: "flex-start",
                }}
                component="a"
                href="https://help.report-supporter.com/ja/collections/8953825-%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactSupportIcon sx={{ fontSize: 20, mr: 0.5 }} />
                <Typography sx={{ fontSize: 13, color: "black" }}>
                  よくある質問
                </Typography>
              </Button>
              <Button
                sx={{
                  color: "rgba(37, 37, 37, 0.70)",
                  px: 1,
                  width: "100%",
                  fontSize: 13,
                  textAlign: "left",
                  justifyContent: "flex-start",
                }}
                component="a"
                href="https://help.report-supporter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TextSnippetIcon sx={{ fontSize: 20, mr: 0.5 }} />
                <Typography sx={{ fontSize: 13, color: "black" }}>
                  ヘルプセンター
                </Typography>
              </Button>
              <Button
                sx={{
                  color: "rgba(37, 37, 37, 0.70)",
                  px: 1,
                  width: "100%",
                  fontSize: 13,
                  textAlign: "left",
                  justifyContent: "flex-start",
                }}
                onClick={showSupportingWidgets}
              >
                <SupportAgentIcon sx={{ fontSize: 20, mr: 0.5 }} />
                <Typography sx={{ fontSize: 13, color: "black" }}>
                  サポートへのお問い合わせ
                </Typography>
              </Button>
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default HelpPopper;
