import React from "react";

type BlueprintSearchContextType = {
  currentKeyword: string | null;
  setCurrentKeyword: React.Dispatch<React.SetStateAction<string | null>>;
};

export const BlueprintSearchContext =
  React.createContext<BlueprintSearchContextType | null>(null);

export const useCurrentKeyword = () => {
  const ctx = React.useContext(BlueprintSearchContext);
  if (ctx == null) {
    throw new Error(
      "useCurrentKeyword must be used within a BlueprintSearchingProvider"
    );
  }
  return ctx.currentKeyword;
};

export const useSetCurrentKeyword = () => {
  const ctx = React.useContext(BlueprintSearchContext);
  if (ctx == null) {
    throw new Error(
      "useSetCurrentKeyword must be used within a BlueprintSearchingProvider"
    );
  }
  return ctx.setCurrentKeyword;
};

export const BlueprintSearchingProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [currentKeyword, setCurrentKeyword] = React.useState<string | null>(
    null
  );

  return (
    <BlueprintSearchContext.Provider
      value={{ currentKeyword, setCurrentKeyword }}
    >
      {children}
    </BlueprintSearchContext.Provider>
  );
};
