import { useShowError, useShowSuccess } from "@/hooks/notification";
import {
  InitialUserProfileData,
  initialUserProfileSchema,
} from "@/hooks/userProfile";
import { useMediaQuery, Dialog, Box, DialogTitle } from "@mui/material";
import React from "react";
import AutocompleteForForm from "./AutocompleteForForm";
import GeneralButton from "./GeneralButton";
import SelectForForm from "./SelectForForm";
import { search } from "@/api/school";
import { SchoolSearchRequest } from "@/api/school";
import { UserProfileCreateRequest } from "@/api/userProfile";
import { School } from "@/utils/interfaces";
import { yupResolver } from "@hookform/resolvers/yup";
import { create } from "@/api/userProfile";
import { useForm, SubmitHandler } from "react-hook-form";

type Props = {
  isOpenUserProfileDialog: boolean;
  onCloseDialog: () => void;
};

const UserProfileDialog: React.FC<Props> = ({
  isOpenUserProfileDialog,
  onCloseDialog,
}) => {
  const showError = useShowError();
  const showSuccess = useShowSuccess();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [currentSchools, setCurrentSchools] = React.useState<School[]>([]);

  const { control, handleSubmit, setValue, getValues, watch } =
    useForm<InitialUserProfileData>({
      resolver: yupResolver(initialUserProfileSchema),
      defaultValues: {
        sex: undefined,
        school_type: undefined,
        school_name: undefined,
        academic_category: undefined,
        graduate_year: undefined,
        prefecture: undefined,
      },
    });

  const schoolType = watch("school_type");
  const schoolName = watch("school_name");

  const fetchSchools = React.useCallback(async () => {
    if (schoolType == null || schoolName == null || schoolName == "") {
      return;
    }

    try {
      const params: SchoolSearchRequest = {
        school_type: schoolType,
        keyword: schoolName,
      };
      const response = await search(params);
      setCurrentSchools(response);
    } catch (err) {
      showError(err);
    }
  }, [showError, schoolType, schoolName]);

  const onSubmit: SubmitHandler<InitialUserProfileData> = React.useCallback(
    async (data: InitialUserProfileData) => {
      try {
        const params: UserProfileCreateRequest = {
          sex: data.sex || null,
          school_type: data.school_type || null,
          school_name: data.school_name || null,
          academic_category: data.academic_category || null,
          major: null,
          graduate_year: data.graduate_year || null,
          phone_number: null,
          postal_code: null,
          prefecture: data.prefecture || "",
          city: null,
          address1: null,
          address2: null,
          self_pr: null,
          interesting_jobs: [],
          interesting_businesses: [],
        };
        await create(params);
        showSuccess("保存しました");
        onCloseDialog();
      } catch (err) {
        showError(err);
      }
    },
    [showError, onCloseDialog, showSuccess]
  );

  React.useEffect(() => {
    fetchSchools();
  }, [schoolType, schoolName, fetchSchools]);

  return (
    <>
      <Dialog fullWidth fullScreen sx={{}} open={isOpenUserProfileDialog}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle
            sx={{
              position: "sticky",
              bottom: 0,
              fontSize: 24,
            }}
          >
            プロフィール情報を入力してください
          </DialogTitle>
        </Box>

        <Box sx={{ width: "70%", mx: "auto" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>基本情報</Box>
            <Box sx={{ my: isMobile ? 1 : 2 }}>
              <SelectForForm<InitialUserProfileData>
                name="sex"
                control={control}
                getValues={getValues}
                setValue={setValue}
                label="性別 +5クレジット"
                items={[
                  { value: "male", label: "男性" },
                  { value: "female", label: "女性" },
                ]}
                fontSize={isMobile ? 12 : 14}
                width={"auto"}
              />
            </Box>
            <Box sx={{ my: isMobile ? 1 : 2 }}>
              <SelectForForm<InitialUserProfileData>
                name="prefecture"
                control={control}
                getValues={getValues}
                setValue={setValue}
                label="所在地 +5クレジット"
                items={[
                  { value: "Hokkaido", label: "北海道" },
                  { value: "Aomori", label: "青森県" },
                  { value: "Iwate", label: "岩手県" },
                  { value: "Miyagi", label: "宮城県" },
                  { value: "Akita", label: "秋田県" },
                  { value: "Yamagata", label: "山形県" },
                  { value: "Fukushima", label: "福島県" },
                  { value: "Ibaraki", label: "茨城県" },
                  { value: "Tochigi", label: "栃木県" },
                  { value: "Gunma", label: "群馬県" },
                  { value: "Saitama", label: "埼玉県" },
                  { value: "Chiba", label: "千葉県" },
                  { value: "Tokyo", label: "東京都" },
                  { value: "Kanagawa", label: "神奈川県" },
                  { value: "Niigata", label: "新潟県" },
                  { value: "Toyama", label: "富山県" },
                  { value: "Ishikawa", label: "石川県" },
                  { value: "Fukui", label: "福井県" },
                  { value: "Yamanashi", label: "山梨県" },
                  { value: "Nagano", label: "長野県" },
                  { value: "Gifu", label: "岐阜県" },
                  { value: "Shizuoka", label: "静岡県" },
                  { value: "Aichi", label: "愛知県" },
                  { value: "Mie", label: "三重県" },
                  { value: "Shiga", label: "滋賀県" },
                  { value: "Kyoto", label: "京都府" },
                  { value: "Osaka", label: "大阪府" },
                  { value: "Hyogo", label: "兵庫県" },
                  { value: "Nara", label: "奈良県" },
                  { value: "Wakayama", label: "和歌山県" },
                  { value: "Tottori", label: "鳥取県" },
                  { value: "Shimane", label: "島根県" },
                  { value: "Okayama", label: "岡山県" },
                  { value: "Hiroshima", label: "広島県" },
                  { value: "Yamaguchi", label: "山口県" },
                  { value: "Tokushima", label: "徳島県" },
                  { value: "Kagawa", label: "香川県" },
                  { value: "Ehime", label: "愛媛県" },
                  { value: "Kochi", label: "高知県" },
                  { value: "Fukuoka", label: "福岡県" },
                  { value: "Saga", label: "佐賀県" },
                  { value: "Nagasaki", label: "長崎県" },
                  { value: "Kumamoto", label: "熊本県" },
                  { value: "Oita", label: "大分県" },
                  { value: "Miyazaki", label: "宮崎県" },
                  { value: "Kagoshima", label: "鹿児島県" },
                  { value: "Okinawa", label: "沖縄県" },
                ]}
                fontSize={isMobile ? 12 : 14}
                width={"auto"}
              />
            </Box>

            <Box sx={{ mt: 5 }}>学歴</Box>
            <Box sx={{ my: isMobile ? 1 : 2 }}>
              <SelectForForm<InitialUserProfileData>
                name="school_type"
                control={control}
                getValues={getValues}
                setValue={setValue}
                label="学校種別 +5クレジット"
                items={[
                  { value: "college", label: "大学" },
                  { value: "graduate_school", label: "大学院" },
                  { value: "college_of_technology", label: "高専" },
                  { value: "junior_college", label: "短大" },
                  { value: "high_school", label: "高等学校" },
                  { value: "vacational_school", label: "専門学校" },
                ]}
                fontSize={isMobile ? 12 : 14}
                width={"auto"}
              />
            </Box>
            <Box sx={{ my: isMobile ? 1 : 2 }}>
              <AutocompleteForForm<InitialUserProfileData>
                name="school_name"
                control={control}
                options={currentSchools?.map((school) => school.school_name)}
                getValues={getValues}
                setValue={setValue}
                placeholder="〇〇大学"
                label="学校名 +5クレジット"
                fontSize={isMobile ? 12 : 14}
                width={"auto"}
              />
            </Box>
            <Box sx={{ my: isMobile ? 1 : 2 }}>
              <SelectForForm<InitialUserProfileData>
                name="academic_category"
                control={control}
                getValues={getValues}
                setValue={setValue}
                label="系統 +5クレジット"
                items={[
                  { value: "information_technology", label: "情報・通信系" },
                  { value: "electrical_engineering", label: "電気・電子系" },
                  { value: "mechanical_engineering", label: "機械・工学系" },
                  { value: "mathematics", label: "理学・数学系" },
                  { value: "architectural_engineering", label: "建築・土木系" },
                  { value: "biochemistry", label: "生物・農学系" },
                  { value: "earth_sciences", label: "地球・環境系" },
                  { value: "economics", label: "経済・商学系" },
                  { value: "law", label: "法学・社会・福祉系" },
                  { value: "education", label: "教育・教員養成系" },
                  { value: "human_sciences", label: "人間・総合科学系" },
                  { value: "physical_education", label: "体育・医療系" },
                  {
                    value: "human_environmental_sciences",
                    label: "生活・家政系",
                  },
                  { value: "international_studies", label: "国際・語学系" },
                  { value: "literature", label: "文化・文学系" },
                  { value: "art", label: "芸術・創作系" },
                  { value: "NA", label: "該当なし" },
                ]}
                fontSize={isMobile ? 12 : 14}
                width={"auto"}
              />
            </Box>
            <Box sx={{ my: isMobile ? 1 : 2 }}>
              <SelectForForm<InitialUserProfileData>
                name="graduate_year"
                control={control}
                getValues={getValues}
                setValue={setValue}
                label="卒業年度 +5クレジット"
                items={[
                  { value: 2020, label: "2020" },
                  { value: 2021, label: "2021" },
                  { value: 2022, label: "2022" },
                  { value: 2023, label: "2023" },
                  { value: 2024, label: "2024" },
                  { value: 2025, label: "2025" },
                  { value: 2026, label: "2026" },
                  { value: 2027, label: "2027" },
                  { value: 2028, label: "2028" },
                  { value: 2029, label: "2029" },
                  { value: 2030, label: "2030" },
                  { value: 2031, label: "2031" },
                  { value: 2032, label: "2032" },
                  { value: 2033, label: "2033" },
                  { value: 2034, label: "2034" },
                  { value: 2035, label: "2035" },
                ]}
                fontSize={isMobile ? 12 : 14}
                width={"auto"}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <GeneralButton
                type="submit"
                variant="contained"
                toneType="light-orange"
                sx={{ mt: 3, ml: 3, width: "30%" }}
                message={"保存"}
              />
            </Box>
          </form>
        </Box>
      </Dialog>
    </>
  );
};

export default UserProfileDialog;
