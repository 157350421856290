export const Sleep = (waitTime: number) => {
  return new Promise((resolve) => setTimeout(resolve, waitTime));
};

export const getFormatedDate = (date: string) => {
  const givenDate = new Date(date);
  const year = givenDate.getFullYear();
  const month = givenDate.getMonth() + 1;
  const day = givenDate.getDate();

  return `${year}年${month}月${day}日`;
};

export const getFormatedDatetime = (date: string) => {
  const givenDate = new Date(date);
  const year = givenDate.getFullYear();
  const month = givenDate.getMonth() + 1;
  const day = givenDate.getDate();
  const hours =
    givenDate.getHours().toString().length == 1
      ? "0" + givenDate.getHours().toString()
      : givenDate.getHours().toString();
  const minutes =
    givenDate.getMinutes().toString().length == 1
      ? "0" + givenDate.getMinutes().toString()
      : givenDate.getMinutes().toString();

  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

export const getMonthFromString = (date: string) => {
  const givenDate = new Date(date);
  return givenDate.getMonth() + 1;
};

export const getDayFromString = (date: string) => {
  const givenDate = new Date(date);
  return givenDate.getDate();
};

export const calculateRemainDays = (date: string) => {
  const givenDate = new Date(date);
  const givenTime = givenDate.getTime();

  const currentDate = new Date();
  const currentTime = currentDate.getTime();

  const timeDifference = givenTime - currentTime;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
};

export const convertHalfNumber = (str: string) => {
  str = str
    .replace(/[０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    })
    .replace(/[^\d]/g, "");
  return Number(str);
};

export const isWebView = (ua: string): boolean => {
  console.log(ua, "userAgent");
  ua = ua.toLowerCase().trim();

  if (ua.includes("crios")) {
    return true;
  }

  const isIOS =
    ua.includes("iphone") || ua.includes("ipod") || ua.includes("ipad");
  const isAndroid = ua.includes("android");

  if (ua.includes("fbios") || ua.includes("fb_iab")) {
    return true;
  }

  if (ua.includes("instagram")) {
    return true;
  }

  if (ua.includes(" line/")) {
    return true;
  }

  if (isIOS && /safari\/[0-9.]+$/.test(ua)) {
    return false;
  }

  if (isAndroid && ua.includes("chrome") && /safari\/[0-9.]+$/.test(ua)) {
    return false;
  }

  return false;
};
