import { logout } from "@/api/auth";
import { useShowError, useShowSuccess } from "@/hooks/notification";
import { User } from "@/utils/interfaces";
import { Sleep } from "@/utils/util";
import {
  Avatar,
  Box,
  Popover,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import { useRouter } from "next/router";
import React from "react";

type Props = {
  user: User;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
};

const UserProfilePopup: React.FC<Props> = ({ user, anchorEl, handleClose }) => {
  const router = useRouter();
  const showSuccess = useShowSuccess();
  const showError = useShowError();

  const handleSignout = React.useCallback(async () => {
    try {
      await logout();
      showSuccess("ログアウトしました");
      await Sleep(500);
      router.reload();
    } catch (err) {
      showError(err);
    }
  }, [showError, showSuccess, router]);

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ mx: 2, my: 2, width: 300 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar alt={user.name} src={user.icon} sx={{ mr: 1 }} />
            <Box>
              <Typography sx={{ fontSize: 20 }}>{user.name}</Typography>
              <Typography
                sx={{ fontSize: 15, color: "rgba(37, 37, 37, 0.70)" }}
              >
                {user.email}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box sx={{ my: 1, display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleSignout}
            sx={{
              color: "#FF0000",
            }}
          >
            ログアウト
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default UserProfilePopup;
