import {
  Autocomplete,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import {
  Controller,
  Control,
  UseFormSetValue,
  UseFormGetValues,
  Path,
} from "react-hook-form";
import React, { SyntheticEvent } from "react";

type Props<T extends Record<string, any>> = React.ComponentProps<
  typeof TextField
> & {
  control: Control<T>;
  name: Path<T>;
  options: string[];
  fontSize: number;
  width: number | string;
  setValue: UseFormSetValue<T>;
  getValues: UseFormGetValues<T>;
  multiple?: boolean;
};

const AutocompleteForForm = <T extends Record<string, any>>({
  control,
  name,
  options,
  fontSize,
  width,
  setValue,
  getValues,
  multiple = false,
  ...textFieldProps
}: Props<T>) => {
  const onChangeAutocomplete = React.useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      value: string | string[] | null
    ) => {
      if (Array.isArray(value)) {
        let newValues: string[] = [];

        for (let i = 0; i < value.length; i++) {
          if (options.includes(value[i])) {
            newValues.push(value[i]);
          }
        }
        setValue(name, newValues as any);
      } else {
        setValue(name, value as any);
      }
    },
    [setValue, name, options]
  );

  const onChangeTextField = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (multiple) {
        if (options.includes(event.target.value)) {
          setValue(name, event.target.value as any);
        }
      } else {
        setValue(name, event.target.value as any);
      }
    },
    [setValue, name, options, multiple]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl
          fullWidth
          error={fieldState.invalid}
          sx={{ background: "white" }}
        >
          <Autocomplete
            {...field}
            size="small"
            options={options}
            multiple={multiple}
            value={getValues(name)}
            onChange={onChangeAutocomplete}
            sx={{
              width: width,
              background: "white",
              "& .MuiInputLabel-asterisk": {
                ml: 0.5,
                color: "rgb(211, 47, 47)",
              },
              "& .MuiFormHelperText-root": {
                background: "#F7E9D7",
                m: 0,
              },
            }}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={onChangeTextField}
                sx={{
                  background: "white",
                  width: "100%",
                  "& .MuiInputLabel-asterisk": {
                    ml: 0.5,
                    color: "rgb(211, 47, 47)",
                  },
                  "& .MuiFormHelperText-root": {
                    background: "#F7E9D7",
                    m: 0,
                  },
                  "& .MuiInputBase-input": {
                    fontSize: fontSize,
                  },
                  "& .MuiFormLabel-root": {
                    fontSize: fontSize,
                  },
                }}
                type="text"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                InputProps={
                  multiple
                    ? { ...params.InputProps }
                    : {
                        ...params.InputProps,
                        startAdornment: <InputLabel shrink></InputLabel>,
                      }
                }
                {...textFieldProps}
              />
            )}
            renderOption={(props, option, state) => {
              return (
                <Typography
                  {...props}
                  sx={{
                    fontSize: fontSize,
                  }}
                >
                  {option}
                </Typography>
              );
            }}
          />
        </FormControl>
      )}
    />
  );
};

export default AutocompleteForForm;
