import { School } from "@/utils/interfaces";
import { apiClient } from "./apiClient";

export type SchoolSearchRequest = {
  school_type: string;
  keyword: string;
};

export const get = async () => {
  const response = await apiClient.get(`/api/schools`);
  const data = response.data as School[];
  return data;
};

export const search = async (params: SchoolSearchRequest) => {
  const response = await apiClient.get(`/api/schools/search`, {
    params: params,
  });
  const data = response.data as School[];
  return data;
};
