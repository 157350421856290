import {
  CurrentEnrollmentRecord,
  CurrentAvailabilityInformation,
} from "@/utils/interfaces";
import { apiClient } from "./apiClient";

export const getCurrentPlan = async () => {
  const resposne = await apiClient.get(`/api/enrollment_record/current_plan`);
  const data = resposne.data as CurrentEnrollmentRecord;

  return data;
};

export const getCurrentAvailabilityInformation = async () => {
  const response = await apiClient.get(
    `/api/enrollment_record/current_availability_information`
  );
  const data = response.data as CurrentAvailabilityInformation;

  return data;
};
