import { envConfig } from "@/utils/env";
import axios from "axios";

export const apiClient = axios.create({
  baseURL: envConfig.NEXT_PUBLIC_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const apiClientMultipart = axios.create({
  baseURL: envConfig.NEXT_PUBLIC_BACKEND_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
  withCredentials: true,
});
