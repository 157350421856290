import { Button, Avatar, Typography } from "@mui/material";
import React from "react";
import { SxProps } from "@mui/system";
import GoogleIcon from "@mui/icons-material/Google";
import { envConfig } from "@/utils/env";

const whiteTone: SxProps = {
  width: 400,
  height: 50,
  background: "white",
  color: "rgba(33, 33, 33, 0.70)",
  ":hover": { backgroundColor: "#ffffff", color: "#000000" },
};

const lightOrangeTone: SxProps = {
  width: 400,
  height: 50,
  backgroundColor: "#FF8C00B2",
  color: "#ffffff",
  ":hover": {
    backgroundColor: "#ff8c00",
    color: "#ffffff",
    borderColor: "#ff8c00",
  },
};

type toneType = "white" | "light-orange";

type Props = React.ComponentProps<typeof Button> & {
  message: string;
  toneType: toneType;
  sx?: any;
  authType: "signup" | "signin";
};

const getButtonTone = (type: toneType): SxProps => {
  switch (type) {
    case "white":
      return whiteTone;
    case "light-orange":
      return lightOrangeTone;
    default:
      return whiteTone;
  }
};

const GoogleAuthButton: React.FC<Props> = ({
  message,
  toneType,
  sx,
  authType,
  ...otherProps
}) => {
  const tone: SxProps = getButtonTone(toneType);

  const defaultSx: SxProps = {
    ...tone,
    ...sx,
  };

  return (
    <>
      <Button
        variant="contained"
        href={`${envConfig.NEXT_PUBLIC_BACKEND_URL}/api/${authType}/google`}
        sx={defaultSx}
        {...otherProps}
      >
        {toneType == "white" ? (
          <Avatar
            alt="google icon"
            src="/images/google.png"
            sx={{ fontSize: 5, mr: 2 }}
          />
        ) : (
          <GoogleIcon sx={{ mr: 1 }} />
        )}

        <Typography sx={{ textTransform: "none" }}>{message}</Typography>
      </Button>
    </>
  );
};

export default GoogleAuthButton;
