import { apiClient } from "./apiClient";

export type SignupWithEmailRequest = {
  email: string;
  password: string;
};

export type SignupWithEmailResponse = {
  message: string;
};

export type SigninWithEmailRequest = {
  email: string;
  password: string;
};

export type SigninWithEmailResponse = {
  message: string;
};

export type ConfirmEmailRequest = {
  confirmation_token: string;
};

export type ConfirmEmailResponse = {
  message: string;
};

export type ResetPasswordRequest = {
  email: string;
};

export type ResetPasswordResponse = {
  message: string;
};

export type ChangePasswordRequest = {
  reset_password_token: string;
  password: string;
};

export type ChangePasswordResponse = {
  message: string;
};

export type LogoutResponse = {
  message: string;
};

export const signupWithEmail = async (params: SignupWithEmailRequest) => {
  const response = await apiClient.post(`/api/signup/email`, params);
  const data = response.data as SignupWithEmailResponse;
  return data;
};

export const signinWithEmail = async (params: SigninWithEmailRequest) => {
  const response = await apiClient.post(`/api/signin/email`, params);
  const data = response.data as SigninWithEmailResponse;
  return data;
};

export const confirmEmail = async (params: ConfirmEmailRequest) => {
  const response = await apiClient.post(`/api/confirm_email`, params);
  const data = response.data as ConfirmEmailResponse;
  return data;
};

export const resetPassword = async (params: ResetPasswordRequest) => {
  const response = await apiClient.post(`/api/reset_password`, params);
  const data = response.data as ResetPasswordResponse;
  return data;
};

export const changePassword = async (params: ChangePasswordRequest) => {
  const response = await apiClient.post(`/api/change_password`, params);
  const data = response.data as ChangePasswordResponse;
  return data;
};

export const logout = async () => {
  const response = await apiClient.post(`/api/logout`);
  const data = response.data as LogoutResponse;
  return data;
};
