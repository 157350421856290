import { NotificationStateContext } from "../utils/notification";
import { AxiosError } from "axios";
import React from "react";
import { useRollbar } from "@rollbar/react";

type ResponseError = {
  message: string;
};

export const useSetNotify = () => {
  const ctx = React.useContext(NotificationStateContext);
  if (ctx == null) {
    throw new Error("setNotify must be used within a NotificationProvider");
  }
  return ctx.setNotify;
};

export const useShowSuccess = () => {
  const setNotify = useSetNotify();
  const showSuccess = React.useCallback(
    (message: string) => {
      setNotify({
        type: "success",
        message: message,
        open: true,
      });
    },
    [setNotify]
  );

  return showSuccess;
};

export const useShowInfo = () => {
  const setNotify = useSetNotify();
  const showInfo = React.useCallback(
    (message: string) => {
      setNotify({
        type: "info",
        message: message,
        open: true,
      });
    },
    [setNotify]
  );

  return showInfo;
};

export const useShowError = () => {
  const rollbar = useRollbar();
  const setNotify = useSetNotify();
  const showError = React.useCallback(
    (error: any) => {
      if (error instanceof AxiosError) {
        if (error.response) {
          const resError = error.response.data as ResponseError;
          setNotify({
            type: "error",
            message: resError.message,
            open: true,
          });
        } else {
          rollbar.error(error);
          setNotify({
            type: "error",
            message: String(error),
            open: true,
          });
        }
      } else {
        rollbar.error(error);
        setNotify({
          type: "error",
          message: String(error),
          open: true,
        });
      }
    },
    [setNotify, rollbar]
  );

  return showError;
};
