import { AffiliateRelation, Affiliater } from "./../utils/interfaces";
import { apiClient } from "./apiClient";

export type AffiliateCreateRequest = {
  affiliater_identifier: string;
};

export type AffiliaterCreateRequest = {
  user_id: number;
};

export const create = async (params: AffiliateCreateRequest) => {
  const response = await apiClient.post(`/api/affiliate`, params);
  const data = response.data as AffiliateRelation;
  return data;
};

export const createAffiliater = async (params: AffiliaterCreateRequest) => {
  const response = await apiClient.post(`/api/affiliater`, params);
  const data = response.data as Affiliater;
  return data;
};
