import {
  BusinessCategoryJPValues,
  JobCategoryJPValues,
  UserProfileAcademicCategoryValues,
  UserProfilePrefectureValues,
  UserProfileSchoolTypeValues,
  UserProfileSexValues,
} from "@/utils/constrants";
import * as yup from "yup";

export type UserProfileData = yup.InferType<typeof userProfileSchema>;

export type InitialUserProfileData = yup.InferType<
  typeof initialUserProfileSchema
>;

export const initialUserProfileSchema = yup.object({
  sex: yup.string().oneOf(UserProfileSexValues).required("性別は必須です"),
  school_type: yup
    .string()
    .oneOf(UserProfileSchoolTypeValues)
    .required("学校種別は必須です"),
  school_name: yup.string().required("学校名は必須です"),
  academic_category: yup
    .string()
    .oneOf(UserProfileAcademicCategoryValues)
    .required("系統は必須です"),
  graduate_year: yup.number().required("卒業年度は必須です"),
  prefecture: yup
    .string()
    .oneOf(UserProfilePrefectureValues)
    .required("都道府県は必須です"),
});

export const userProfileSchema = yup.object({
  sex: yup
    .string()
    .nullable()
    .oneOf([...UserProfileSexValues, null])
    .defined("性別は必須です"),
  school_type: yup
    .string()
    .nullable()
    .oneOf([...UserProfileSchoolTypeValues, null])
    .defined("学校種別は必須です"),
  school_name: yup.string().nullable().defined("学校名は必須です"),
  academic_category: yup
    .string()
    .nullable()
    .oneOf([...UserProfileAcademicCategoryValues, null])
    .defined("系統は必須です"),
  major: yup.string().nullable().defined("専攻は必須です"),
  graduate_year: yup.number().nullable().defined("卒業年度は必須です"),
  phone_number: yup.string().nullable().defined("電話番号は必須です"),
  postal_code: yup.string().nullable().defined("郵便番号は必須です"),
  prefecture: yup
    .string()
    .nullable()
    .oneOf([...UserProfilePrefectureValues, null])
    .defined("都道府県は必須です"),
  city: yup.string().nullable().defined("市区町村は必須です"),
  address1: yup.string().nullable().defined("住所1は必須です"),
  address2: yup.string().nullable().defined("住所2は必須です"),
  self_pr: yup.string().nullable().defined("自己PRは必須です"),
  interesting_jobs: yup
    .array()
    .of(
      yup
        .string()
        .nullable()
        .oneOf(JobCategoryJPValues)
        .defined("職種は必須です")
    )
    .nullable()
    .defined("興味のある職種は必須です"),
  interesting_businesses: yup
    .array()
    .of(
      yup
        .string()
        .nullable()
        .oneOf(BusinessCategoryJPValues)
        .defined("業界は必須です")
    )
    .nullable()
    .defined("興味のある業界は必須です"),
});
