import { Blueprint } from "@/utils/interfaces";
import { getFormatedDatetime } from "@/utils/util";
import { Box, Paper, Typography } from "@mui/material";
import { useRouter } from "next/router";
import React from "react";

type Props = {
  blueprints: Blueprint[];
  isMobile: boolean;
};

const BlueprintListInSearchBox: React.FC<Props> = ({
  blueprints,
  isMobile,
}) => {
  const router = useRouter();
  return (
    <>
      {blueprints.map((blueprint, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            py: 3,
            width: "100%",
            cursor: "pointer",
            ":hover": {
              backgroundColor: "#F1F1F0",
            },
          }}
          onClick={() => router.push(`/report/${blueprint.id}`)}
        >
          <Box sx={{ height: isMobile ? 120 : 200 }}>
            <Paper
              sx={
                isMobile
                  ? {
                      height: 120,
                      width: 90,
                      px: 1,
                      py: 1.5,
                      mx: 1,
                      overflow: "hidden",
                      flexGrow: 1,
                    }
                  : {
                      height: 200,
                      width: 150,
                      px: 2,
                      py: 1.5,
                      mx: 3,
                      overflow: "hidden",
                      flexGrow: 1,
                    }
              }
              elevation={3}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: blueprint.latest_snapshot
                    ? blueprint.latest_snapshot.html
                    : "",
                }}
                style={{ fontSize: 8 }}
              />
            </Paper>
          </Box>
          <Box
            sx={{
              mt: 1,
              height: isMobile ? 120 : 200,
              overflow: "hidden",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
              {blueprint.title}
            </Typography>
            <Typography
              sx={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 5,
                textOverflow: "ellipsis",
              }}
            >
              {blueprint.latest_snapshot
                ? blueprint.latest_snapshot.html.replace(/<\/?[^>]+(>|$)/g, "")
                : ""}
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 3 }}>
              <Typography
                sx={
                  isMobile
                    ? { fontSize: 10, color: "rgba(37, 37, 37, 0.70)", mr: 1 }
                    : {
                        fontSize: 12,
                        color: "rgba(37, 37, 37, 0.70)",
                        mr: 4,
                      }
                }
              >
                作成日時: {getFormatedDatetime(blueprint.created_at)}
              </Typography>
              <Typography
                sx={
                  isMobile
                    ? { fontSize: 10, color: "rgba(37, 37, 37, 0.70)" }
                    : { fontSize: 12, color: "rgba(37, 37, 37, 0.70)" }
                }
              >
                最終更新日時:{" "}
                {blueprint.latest_snapshot
                  ? getFormatedDatetime(blueprint.latest_snapshot.created_at)
                  : ""}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default BlueprintListInSearchBox;
